<template>
  <div class="hello">
    <section class="banner home">
      <map-view style="height: 30vh" :center="center" :zoom="8" :markers="markers" class="w-full" />
      <!-- quick search bar -->
      <div class="container mx-auto" style="z-index: 400">
        <form class="w-full p-5">
          <div class="grid grid-cols-12 gap-4 bg-gray-200 px-6 py-8 rounded-xl search-bar">
            <div class="col-span-3">
              <AutoComplete
                class="w-full p-fluid"
                v-model="category"
                field="name"
                :suggestions="filtered"
                placeholder="All Services"
                @complete="searchCategory($event)"
                :dropdown="false">
                <template #item="slotProps">
                  <div class="flex align-options-center">
                    <div class="grid w-full">
                      <div class="col-10 pb-0">{{ slotProps.item.name }}</div>
                      <div class="col-4 pt-0 text-xs">{{ slotProps.item.breadcrumb }}</div>
                    </div>
                  </div>
                </template>
              </AutoComplete>
            </div>
            <div class="col-span-5 md:col-span-6 lg:col-span-7">
              <InputText type="text" v-model="location" class="w-full" placeholder="What are you looking for?" />
            </div>
            <div class="col-span-4 md:col-span-3 lg:col-span-2">
              <Button icon="pi pi-search" label="Search Now" class="w-full" @click.prevent="search" />
            </div>
          </div>
        </form>
      </div>
      <!-- end of quick search bar -->
    </section>

    <section class="carousal-section popular-categories" v-if="consultantsVisible">
      <div class="container mx-auto">
        <div class="text-center pb-12 top-heading">
          <h1 class="pb-4">It's time to unlock your business's full potential</h1>
          <h2 class="text-left">Most Popular Categories</h2>
          <div class="carousel-container">
            <Carousel :value="items" :numVisible="6" :numScroll="6" :responsiveOptions="responsiveOptions">
              <template #header> </template>
              <template #item="slotProps">
                <router-link to="/" class="cat-item">
                  <div class="item-content">
                    <div class="mb-3">
                      <img :src="slotProps.data.image" :alt="slotProps.data.title" class="product-image" />
                    </div>
                    <div>
                      <h4 class="mb-1">{{ slotProps.data.title }}</h4>
                      <!-- Add more details if necessary -->
                    </div>
                  </div>
                </router-link>
              </template>
            </Carousel>
            <router-link to="/" class="all-cat text-left">All Categories</router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="two-column-container video">
      <div class="container mx-auto pt-8 pb-8">
        <div class="flex justify-center items-center">
          <div class="text-center w-1/2">
            <p class="mb-4 text-left">Our mission is simple—to empower SMEs by making it easier than ever to access the resources and support they need..Sign Up</p>
            <a class="signup_btn p-button">Sign up</a>
          </div>

          <div class="w-1/2 p-4">
            <template>
              <div class="flex justify-center items-center bg-gray-100 p-4">
                <div class="w-full max-w-3xl">
                  <iframe
                    class="w-full h-64 md:h-80"
                    src="https://www.youtube.com/embed/wDchsz8nmbo"
                    title="video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <section class="carousal-section top-experts" v-if="consultantsVisible">
      <div class="container mx-auto">
        <div class="text-center pb-12 top-heading">
          <h2 class="text-left">Top Experts</h2>
          <div class="carousel-container">
            <Carousel :value="items" :numVisible="6" :numScroll="6" :responsiveOptions="responsiveOptions">
              <template #header> </template>
              <template #item="slotProps">
                <router-link to="/" class="cat-item">
                  <div class="item-content">
                    <div class="mb-3">
                      <img :src="slotProps.data.image" :alt="slotProps.data.title" class="product-image" />
                    </div>
                    <div>
                      <h4 class="mb-1">{{ slotProps.data.title }}</h4>
                      <!-- Add more details if necessary -->
                    </div>
                  </div>
                </router-link>
              </template>
            </Carousel>
            <router-link to="/" class="all-cat text-left">All Expert Services</router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="two-column-container">
      <div class="container mx-auto pt-8 pb-8">
        <div class="flex justify-center items-center">
          <!-- <div class="w-1/2 p-4">
            <p>Joining SMESPOT is as easy as one, two, three. Sign up, tell us about your business, and start connecting with the world's largest SME support network.</p>
          </div> -->

          <div class="text-center w-1/2">
            <p class="mb-4 text-left">
              Joining SMESPOT is as easy as one, two, three. Sign up, tell us about your business, and start connecting with the world's largest SME support network.
            </p>
            <a class="signup_btn p-button">Sign up</a>
          </div>

          <!-- <a class="signup_btn" @click.prevent="active_modal = 'signup'" v-if="user == null">Sign up</a> -->
          <div class="w-1/2 p-4">
            <template>
              <div class="flex justify-center items-center p-4">
                <div class="w-full max-w-3xl">
                  <img src="@/assets/images/home-page-cta.png" class="mx-auto" alt="home-page" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationReceiver from '@/plugins/location-receiver.js';
import MapView from '@/components/MapView.vue';
import Carousel from 'primevue/carousel';
import AutoComplete from 'primevue/autocomplete';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import category_service from '@/services/category_service';
import consultant_service from '@/services/consultant_service';
import auth_service from '@/services/auth_service';

export default {
  name: 'Home',
  components: {
    'map-view': MapView,
    // consultant: Consultant,
    Carousel,
    AutoComplete,
    InputText,
    Button,
  },
  data: () => {
    return {
      lat: 0,
      lng: 0,
      zoom: 10,
      consultants: [],
      categories: [],
      filtered: [],
      location: '',
      category: '',
      items: [
        { image: 'https://placehold.co/800', title: 'Item 1' },
        { image: 'https://placehold.co/800', title: 'Item 2' },
        { image: 'https://placehold.co/800', title: 'Item 3' },
        { image: 'https://placehold.co/800', title: 'Item 4' },
        { image: 'https://placehold.co/800', title: 'Item 5' },
        { image: 'https://placehold.co/800', title: 'Item 6' },
        { image: 'https://placehold.co/800', title: 'Item 7' },
        { image: 'https://placehold.co/800', title: 'Item 8' },
        { image: 'https://placehold.co/800', title: 'Item 9' },
        { image: 'https://placehold.co/800', title: 'Item 10' },
        { image: 'https://placehold.co/800', title: 'Item 11' },
        { image: 'https://placehold.co/800', title: 'Item 12' },
      ],
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: '600px',
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: '480px',
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  computed: {
    center() {
      return [this.lat, this.lng];
    },
    consultantsVisible() {
      return this.consultants.length > 0;
    },
    top6() {
      return this.consultants.map((consultant) => {
        return {
          id: consultant.id,
          name: consultant.name,
          tagline: consultant.tagline,
          description: consultant.description,
          rating: 5,
        };
      });
    },
    markers() {
      return this.consultants.map((consultant) => {
        return {
          latLng: [consultant.latitude, consultant.longitude],
          popup: consultant.name,
        };
      });
    },
  },
  methods: {
    search() {
      this.$router.push({ name: 'search_page', params: { category: this.category } });
    },
    searchCategory(event) {
      if (!event.query.trim().length) {
        this.filtered = [...this.categories];
      } else {
        this.filtered = this.categories.filter((cat) => {
          return cat.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
  },
  mounted() {
    LocationReceiver.getLocation().then(({ latitude, longitude }) => {
      this.lat = latitude;
      this.lng = longitude;
    });
    consultant_service.top12().then(({ data }) => {
      if (data.status && Array.isArray(data.consultants)) {
        this.consultants = data.consultants;
      }
    });
    category_service.getCategories().then(({ data }) => (this.categories = data.categories));
    auth_service.getUserInfo().then(({ data }) => this.$store.dispatch('setUser', data.user));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
